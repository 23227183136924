import * as React from "react";
import { Fragment } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Popover, Transition } from "@headlessui/react";
import {
  AnnotationIcon,
  ChatAlt2Icon,
  InboxIcon,
  QuestionMarkCircleIcon,
  BookmarkAltIcon,
  CalendarIcon,
  ChartBarIcon,
  CursorClickIcon,
  MenuIcon,
  PhoneIcon,
  PlayIcon,
  RefreshIcon,
  ShieldCheckIcon,
  SupportIcon,
  ViewGridIcon,
  XIcon,
} from "@heroicons/react/outline";

import VideoExample from "../videos/example.mp4";

import { ChevronDownIcon } from "@heroicons/react/solid";

const features = [
  {
    name: "Analytics",
    href: "#",
    description:
      "Get a better understanding of where your traffic is coming from.",
    icon: ChartBarIcon,
  },
  {
    name: "Engagement",
    href: "#",
    description: "Speak directly to your customers in a more meaningful way.",
    icon: CursorClickIcon,
  },
  {
    name: "Security",
    href: "#",
    description: "Your customers' data will be safe and secure.",
    icon: ShieldCheckIcon,
  },
  {
    name: "Integrations",
    href: "#",
    description: "Connect with third-party tools that you're already using.",
    icon: ViewGridIcon,
  },
  {
    name: "Automations",
    href: "#",
    description:
      "Build strategic funnels that will drive your customers to convert",
    icon: RefreshIcon,
  },
];
const callsToAction = [
  { name: "Watch Demo", href: "#", icon: PlayIcon },
  { name: "Contact Sales", href: "#", icon: PhoneIcon },
];

const solutions = [
  {
    name: "Inbox",
    description:
      "Get a better understanding of where your traffic is coming from.",
    href: "#",
    icon: InboxIcon,
  },
  {
    name: "Messaging",
    description: "Speak directly to your customers in a more meaningful way.",
    href: "#",
    icon: AnnotationIcon,
  },
  {
    name: "Live Chat",
    description: "Your customers' data will be safe and secure.",
    href: "#",
    icon: ChatAlt2Icon,
  },
  {
    name: "Knowledge Base",
    description: "Connect with third-party tools that you're already using.",
    href: "#",
    icon: QuestionMarkCircleIcon,
  },
];

const people = [
  {
    name: "JOUNAID BEAUFILS",
    imageUrl: "/img/people/jounaid.webp",
    bio: "Jounaid is a tech lover and a logician, he studies in the science program of Maastricht University - nothing scientific won’t interest him. He's putting his brains behind making Upstanders an awesome tool. Because he had to deal face to face with sexual aggression, he knows it needs to work.",
  },
  {
    name: "RACHEL GOH",
    imageUrl: "/img/people/rachel.png",
    bio: "Rachel is an artist, a creative thinker and a connector. After a decade in advertising, she has been working the last 10 years as a partner for Innate Motion, helping companies and brand build brands that make a real difference for people.",
  },
  {
    name: "BENOIT BEAUFILS",
    imageUrl: "/img/people/benoit.webp",
    bio: "Benoit worked for companies where we was running marketing work. He co-founded the consultancy Innate Motion, which helps big companies like Unilever or Danone, but also start-ups, to build brands that make a positive impact on the world. Everything he learned there, he now pours into Upstanders. Because he's lived with sexual violence survivors, and knows it's a battle worth the fight.",
  },
  {
    name: "AND EVERYONE ELSE!",
    imageUrl: "/img/people/all.webp",
    bio: "Mateja, a jurist and a judge who dealt with the legal part sexual violence all her life, Ana, Fernanda, Mark, and many others who work with Innate Motion and joined us to think about it at the beginning, Mike, Kathrine or Lydia who shared insights and idea. Rise.Band is brought together by a passionate collective - join us!",
  },
];

const navigation = [
  // { name: "Home", href: "#" },
  { name: "About", href: "#" },
  { name: "CitronFuel", href: "#" },
  { name: "Waste", href: "#" },
  { name: "Cement", href: "#" },
  { name: "Green", href: "#" },
  { name: "People", href: "#" },
  { name: "Media", href: "#" },
  { name: "Contact", href: "#" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Video() {
  return (
    <div className="min-h-screen">
      <header className="relative flex justify-center h-screen mb-12 overflow-hidden al">
        <Popover className=" z-30 text-white relative ">
          <>
            <div className="flex justify-between items-center max-w-7xl mx-auto px-4 py-6 sm:px-6 md:justify-start md:space-x-10 lg:px-8 ">
              <div>
                <StaticImage
                  src="../images/avert-logo-white.svg"
                  alt="Avert logo"
                  placeholder="TRACED_SVG"
                  layout="fixed"
                  width={150}
                  height={38}
                />
              </div>
              <Popover.Group as="nav" className="hidden md:flex space-x-10 ">
                <Popover className="relative">
                  {({ open }) => (
                    <>
                      <Popover.Button
                        className={classNames(
                          open ? "text-white" : "text-white",
                          "group rounded-md inline-flex items-center text-base font-medium hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
                        )}
                      >
                        <span>Who we are</span>
                        <ChevronDownIcon
                          className={classNames(
                            open ? "text-white" : "text-white",
                            "ml-2 h-5 w-5 group-hover:text-white"
                          )}
                          aria-hidden="true"
                        />
                      </Popover.Button>
                    </>
                  )}
                </Popover>

                <Popover className="relative">
                  {({ open }) => (
                    <>
                      <Popover.Button
                        className={classNames(
                          open ? "text-white" : "text-white",
                          "group rounded-md inline-flex items-center text-base font-medium hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
                        )}
                      >
                        <span>What we do</span>
                        <ChevronDownIcon
                          className={classNames(
                            open ? "text-white" : "text-white",
                            "ml-2 h-5 w-5 group-hover:text-white"
                          )}
                          aria-hidden="true"
                        />
                      </Popover.Button>
                    </>
                  )}
                </Popover>

                <Popover className="relative">
                  {({ open }) => (
                    <>
                      <Popover.Button
                        className={classNames(
                          open ? "text-white" : "text-white",
                          "group  rounded-md inline-flex items-center text-base font-medium hover:text-white focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-white"
                        )}
                      >
                        <span>News & events</span>
                        <ChevronDownIcon
                          className={classNames(
                            open ? "text-white" : "text-white",
                            "ml-2 h-5 w-5 group-hover:text-white"
                          )}
                          aria-hidden="true"
                        />
                      </Popover.Button>
                    </>
                  )}
                </Popover>
              </Popover.Group>
            </div>

            <div className="relative px-4 py-48 sm:px-6 sm:py-48 lg:py-48 lg:px-8 ">
              <h1 className="text-white text-center text-3xl sm:text-3xl lg:text-3xl pt-6">
                <span className="invisible mt-12">
                  <p className="invisible mt-48">
                    {" "}
                    Using knowledge to change lives Using knowledge to change
                  </p>
                </span>
                <span className="  block font-Montserrat text-5xl font-extrabold">
                  Using knowledge to change lives
                </span>
              </h1>
              <div className="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center">
                <div className="space-y-4 sm:space-y-0 sm:mx-auto sm:inline-grid sm:grid-cols-2 sm:gap-5">
                  <a
                    href="#"
                    className="flex items-center justify-center px-4 py-3 text-black text-base font-medium rounded-md shadow-sm  bg-white sm:px-8"
                  >
                    Find out more
                  </a>
                  <a
                    href="#"
                    className="flex items-center justify-center px-4 py-3  text-base font-medium rounded-md shadow-sm border border-primary text-white  bg-primary sm:px-8"
                  >
                    Our projects
                  </a>
                </div>
              </div>
            </div>
          </>
        </Popover>

        <video
          autoPlay
          loop
          muted
          className="absolute z-10 w-auto min-w-full min-h-full max-w-none"
        >
          <source src={VideoExample} />
          Your browser does not support the video tag.
        </video>
      </header>
    </div>
  );
}
